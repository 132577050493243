<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Schedule a Checklist</h4>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <sheduleChecklist />
                        </b-col>
                    </b-row>
                    
                </b-card>
            </b-col>
        </b-row>
        
        
    </div>
</template>

<script>
import { bus } from '../../main'
import { mapMutations, mapActions } from 'vuex'
import sheduleChecklist from '../../components/scheduleChecklist.vue'
export default {
    components: { sheduleChecklist },
    data: () => ({
        state: 'initiliazing'
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Scheduled Checklists'
            },
            {
                text: 'Create',
                active: true
            }
        ])

        bus.$off()
        
        bus.$on('saveSchedule', () => {
            this.state = 'loading'
            this.createNewSchedule()
            .then((res) => {
                //console.log('res', res)
                if (res.data.result.alreadyScheduled === true) {
                    this.clearMessage()
                    this.addWarningMessage('This schedule based on persons, topic and due date already exsist. And cannot be scheduled with the same parameters')
                } else {
                    this.$router.push({path: '/checklist/schedule/search'})
                }
            })
            .catch(() => {
                this.state = 'show'
            })
        })
    
    },
    methods:{
        ...mapActions(['createNewSchedule']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions('notificationService', ['addWarningMessage', 'clearMessage']),
    },
    computed:{
    }
}
</script>
